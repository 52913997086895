html,
body {
  height: 100%;
  margin: 0px;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#header {
  background: linear-gradient(to bottom, #272727 1%, #333 100%);
  height: 38px;
  width: 100%;
}
#rw-logo {
  height: 30px;
  padding: 4px;
  position: absolute;
  top: 2px;
  left: 5px;
  color: rgba(255, 248, 248, 0.9);
  text-decoration: none;
  font-size: 1.5em;
  font-family: 'Scope One', serif;
}

#rw-logo img {
  max-height: 32px;
}

#header h1 {
  margin-top: 0;
  color: #ffffffb3;

  position: relative;
  left: 46px;

  font-weight: normal;
  font-size: 1.5em;

  line-height: 38px;
  width: 200px;
  display: inline-block;
}

#header input.search {
  width: 200px;
  margin-right: 5px;
}

a.doi,
a.folder,
a.project {
  color: #000;
}

a.doi:hover,
a.folder:hover {
  text-decoration: none;
}

a.project {
  text-decoration: none;
}

a.project:hover {
  text-decoration: underline;
}

a.legacy {
  color: #bf4800 !important;
}
a.legacy::after {
  content: ' (*)';
}

i.obsolete {
  opacity: 0.6;
  font-size: 16px;
  line-height: 26px;
  margin-left: 5px;
}
